<template>
    <div id="domain-preview">
        <vs-sidebar hidden-background position-right v-model="active" click-not-close class="items-no-padding">
            <div class="h-full">
                <div class="customizer-header mt-6 flex items-center justify-between px-6">
                    <div class="truncate w-75">
                        <h4 class="truncate flex items-center">
                            <div class="truncate">Preview <template v-if="domain['details']['domain']">- {{ domain['details']['domain'] }}</template></div>
                            <span :class="`badge mx-3 ${getStatusBadge(domain['details']['status'])}`">{{ domain['details']['status'] }}</span>
                        </h4>
                        <small>View details of selected domain</small>
                    </div>
                    <feather-icon icon="XIcon" @click.stop="closeDomainPreview()" class="cursor-pointer"></feather-icon>
                </div>
                <vs-divider class="mb-0"></vs-divider>

                <VuePerfectScrollbar class="scroll-area--domain-preview pt-4 pb-6" :settings="settings">
                    <div class="px-6">

                        <div>
                            <h5 class="mb-4">Basic Details</h5>
                            <div class="vx-row">
                                <div class="vx-col sm:w-1/2 w-full mb-4">
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <vs-input class="w-full label-input" label="Domain" :value="domain['details']['domain']" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-col sm:w-1/2 w-full mb-4">
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <vs-input class="w-full label-input" label="Domain ID" :value="domain['details']['domain_id']" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-col sm:w-1/2 w-full mb-4">
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <vs-input class="w-full label-input" label="Creation Date" :value="domain['details']['transaction_date']" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-col sm:w-1/2 w-full mb-4">
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <vs-input class="w-full label-input" label="Period" :value="formatYears(domain['details']['period'])" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-col sm:w-1/2 w-full mb-4">
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <vs-input class="w-full label-input" label="Registrar Name" :value="domain['details']['registrar_organization']" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-col sm:w-1/2 w-full mb-4">
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <vs-input class="w-full label-input" label="Registrant Name" :value="domain['details']['registrant_name']" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-col sm:w-1/2 w-full mb-4">
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <vs-input class="w-full label-input" label="Registrant Email" :value="domain['details']['registrant_email']" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-col sm:w-1/2 w-full mb-4">
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <vs-input class="w-full label-input" label="Registrant Country" :value="domain['details']['registrant_country']" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-col sm:w-1/2 w-full mb-4">
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <vs-input class="w-full label-input" label="Registrant Phone" :value="domain['details']['registrant_phone_voice']" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-col sm:w-1/2 w-full mb-4">
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <vs-input class="w-full label-input" label="Domain Status" :value="formatStatus(domain['details']['status'])" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-col sm:w-1/2 w-full mb-4">
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <label class="vs-input--label">Nameservers</label>
                                            <vs-textarea class="w-full label-input" :value="domain['details']['nameservers']" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-col sm:w-1/2 w-full mb-4">
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <vs-input class="w-full label-input" label="Domain Locked" :value="formatLocked(domain['details']['locked'])" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <vs-divider></vs-divider>

                        <template v-if="domain['details']['suspicion_level'] != 'none'">
                            <div>
                                <h5 class="mb-4 flex items-center">
                                    <div>Talos:</div>
                                    <span :class="`badge mx-3 ${getTalosBadge(domain['details']['suspicion_level'])}`">{{ domain['details']['suspicion_level'] }}</span>
                                </h5>
                            </div>
                            <vs-divider></vs-divider>
                        </template>

                        <div>
                            <h5 class="mb-4">History Timeline</h5>
                            <div class="status-timeline">
                                <template v-if="domain['timeline'].length">
                                    <div v-for="(row, index) in domain['timeline']" :key="index" class="status-timeline__row flex">
                                        <div class="status-timeline__date flex items-center relative">{{ $moment.utc(row['date_time']).format('DD-MM-YYYY HH:mm:ss') }}</div>
                                        <div class="status-timeline__status"><span class="badge" :class="'badge--'+getTimelineEventCLass(row['event'])">{{ row['event'] }}<template v-if="row['source']"> - {{ row['source'] }}</template></span></div>
                                    </div>
                                </template>
                                <template v-else>
                                    <small>No data available</small>
                                </template>
                            </div>
                        </div>
                        
                        <div class="mt-6 mb-4">
                            <vs-button class="block w-full" type="border" color="dark" @click="viewMoreDetails()">More Details</vs-button>
                        </div>

                    </div>
                </VuePerfectScrollbar>
            </div>
        </vs-sidebar>
    </div>
</template>

<script>
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'

    export default {
        props: {
            domainId: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                active: false,
                domain: {
                    details: {},
                    timeline: []
                },
                settings: { // perfectscrollbar settings
                    maxScrollbarLength: 60,
                    wheelSpeed: .60,
                },
            }
        },
        watch: {
            '$route.params.id': function(val) {
                if(val) {
                    this.getDomainDetails(val);
                }
            }
        },
        computed: {
            previousLink: function() {
                // Get parent name
                return this.$route['matched'][2]['parent']['name'];
            },
        },
        methods: {
            closeDomainPreview: function () {
                this.active = false;
                setTimeout(() => {
                    // Go back to parent route
                    this.$router.push({ name: this.previousLink });
                }, 50);
            },
			formatYears(years) {
                if(years) {
                    return years == 1 ? `${years} year` : `${years} years`;
                }
                else {
                    return '';
                }
			},
			formatStatus(status) {
				switch (status) {
					case 'SH':
						return 'Server Hold';
					case 'D':
						return 'Deleted';
					case 'PD':
						return 'Pending Delete';
					case 'SHF':
						return 'Server Hold Failed';
					case 'SHP':
						return 'Server Hold In Progress';
					case 'A':
						return 'Active';
					default:
						return '';
				}
            },
			formatLocked(flag) {
                switch (flag) {
                    case 0:
                        return 'No'
                    case 1:
                        return 'Yes'
                    default:
                        return ''
                }
            },
            getStatusBadge(status) {
                switch (status) {
                    case 'A':
                        return `badge--success`
                    case 'SH':
                        return `badge--danger`
                    case 'SHP':
                        return `badge--warning`
                    case 'SHF':
                        return `badge--warning`
                    case 'PD':
                        return `badge--info`
                    case 'D':
                        return `badge--dark`
                    default:
                        return `hidden`
                }
            },
            getTalosBadge(status) {
                switch (status) {
                    case 'high':
                        return `badge--danger`
                    case 'medium':
                        return `badge--warning`
                    case 'low':
                        return `badge--primary`
                    default:
                        return `hidden`
                }
            },
            getDomainDetails(id) {
                let self = this;
                // Get Domain Details
                this.$axiosSecure.get('/domain-search-id', {
                    params: {
                        id: id
                    }
                })
                .then((response) => {
					// Assign Data
					if(!response.data.noResults) {
                        self.domain['details'] = response.data['details']['lifecycle'][0];
                        self.domain['timeline'] = response.data['details']['timeline'];
					}
					else {
                        // No Results notification
                        self.$vs.notify({
                            color: 'warning',
                            title: 'No results',
                            text: 'Details for this domain aren\'t  available'
                        });
					}
                })
                .catch((error) => {
                    // Error notification
                    self.$vs.notify({
                        color: 'danger',
                        title: 'Something went wrong',
                        text: 'Please contact the server admin'
                    });

                    console.log(error);
                });
            },
            viewMoreDetails() {
                let routeData = this.$router.resolve({name: 'search', query: {domain_id: this.domain['details']['domain_id']}});
                window.open(routeData.href, '_blank');
            },
            getTimelineEventCLass(event) {
                if (event.includes('Registration')) {
                    return 'success';
                }
                else if (event.includes('Detection')) {
                    return 'warning';
                }
                else if (event.includes('Suspension')) {
                    return 'danger';
                }
                else if (event.includes('Unsuspension')) {
                    return 'primary';
                }
                else if (event.includes('Deletion')) {
                    return 'dark';
                }
                else if (event.includes('Registry Lock')) {
                    return 'info';
                }
                else if (event.includes('Registry Unlock')) {
                    return 'info';
                }
                else {
                    return 'dark';
                }
            }
        },
        components: {
            VuePerfectScrollbar,
        },
        mounted() {
            // Toggle show
            setTimeout(() => {
                this.active = true;
            }, 100);
            // Get Domain Details
            this.getDomainDetails(this.$route.params.id);
        }
    }
</script>

<style lang="scss">
#domain-preview {
    .vs-sidebar{
        position: fixed;
        z-index: 52000;
        width: 600px;
        max-width: 90vw;
        
        @media only screen and (min-width: 992px) {
            width: 40%;
        }
    }
}

.scroll-area--domain-preview {
    height: calc(100% - 5rem);
}
</style>
